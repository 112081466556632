<template>
  <b-card
    title="List Applicant"
    no-body
  >
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">
        <b-button-group>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-modal.modal-filter
                >
                Filter
            </b-button>
        </b-button-group>

        <!-- filter -->
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      show-empty
    >
      <template #cell(avatar)="data">
        <b-avatar :src="data.value" />
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data" width="160px">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="gotoDetailInfluencer(data.item.id)"
              >
              <feather-icon icon="EyeIcon" />
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon"
              @click="setujuiApplicant(data.item.id)"
              v-show="!hasInfluencer && data.item.status_approval=='pending'"
              >Setujui
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon"
              @click="tolakApplicant(data.item.id)"
              v-show="!hasInfluencer && data.item.status_approval=='pending'"
              >Tolak
          </b-button>
      </template>
      <template #empty>
        <div role="alert" aria-live="polite">
            <div class="text-center my-2">Tidak ada data</div>
        </div>
      </template>
      <template #emptyfiltered>
        <div role="alert" aria-live="polite">
            <div class="text-center my-2">Tidak ada data</div>
        </div>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <!-- basic modal -->
    <b-modal
      id="modal-filter"
      ref="modal-filter"
      title="Filter Berdasarkan"
      ok-title="Filter"
      cancel-title="Reset"
      hide-footer
      size="lg"
    >
      <b-row class="p-1">
        <b-col cols="12">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Age"
                  label-for="h-age"
                  label-cols-md="4"
                >
                  <b-form-select
                    v-model="selectedAge"
                    :options="optionsAge"></b-form-select>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Gender"
                  label-for="h-gender"
                  label-cols-md="4"
                >
                  <b-form-select
                    v-model="selectedGender"
                    :options="optionsGender"></b-form-select>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Status"
                  label-for="h-status"
                  label-cols-md="4"
                >
                  <b-form-select
                    v-model="selectedStatus"
                    :options="optionsStatus"></b-form-select>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Location"
                  label-for="h-location"
                  label-cols-md="4"
                >
                  <b-form-select
                    v-model="selectedLocation"
                    :options="optionsLocation"></b-form-select>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1 ml-1 float-right"
                  @click="filterCampaign()"
                >
                  Filter
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  class="float-right"
                  @click="resetFilter()"
                >
                  Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal ref="my-modal-setuju" @ok="setuju" ok-title="Ya, Setuju!" @cancel="hideModalSetuju" cancel-title="Batal">
      <div class="d-block text-center">
        <h6>Apakah Anda yakin akan menyetujui influencer ini?</h6>
      </div>
    </b-modal>

    <b-modal ref="my-modal-tolak" @ok="tolak" ok-title="Ya, Tolak!" @cancel="hideModalTolak" cancel-title="Batal">
      <div class="d-block text-center">
        <h6>Apakah Anda yakin akan menolak influencer ini?</h6>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BCard, BButtonGroup, BRow, BCol, BForm
} from 'bootstrap-vue'
import { codeKitchenSink } from './code'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BCard,
    BButtonGroup,
    BRow,
    BCol,
    BForm
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'no', label: 'No',
        },
        {
          key: 'firstName', label: 'Nama Influencer',
        },
        {
          key: 'age', label: 'Usia',
        },
        {
          key: 'gender', label: 'Jenis Kelamin',
        },
        {
          key: 'status_influencer', label: 'Status Influencer',
        },
        {
          key: 'status_approval', label: 'Status Persetujuan',
        },
        {
          key: 'action', label: 'Aksi',
        }
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      codeKitchenSink,

      hasInfluencer: false,
      selectedInfluencer: null,
      selectedAge: null,
      selectedGender: null,
      selectedStatus: null,
      selectedLocation: null,
      optionsAge: {},
      optionsGender: [
        { value: null, text: 'Pilih Gender' },
        { value: 'gender=Laki-Laki', text: 'Laki-Laki' },
        { value: 'gender=Perempuan', text: 'Perempuan' }
      ],
      optionsStatus: [
        { value: null, text: 'Pilih Status' },
        { value: 'verified=1', text: 'Verified' },
        { value: 'verified=0', text: 'Non Verified' }
      ],
      optionsLocation: {}
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
      let _ = this
    // Set the initial number of items
    _.totalRows = _.items.length
    _.getData()
    _.getAgeRange()
    _.getLocation()
  },
  methods: {
    getData() {
      let _ = this
      let filter = _.getFilter()
      // /advertiser/campaigns/:id/submissions?page=1&limit=200
      axios.get('/advertiser/campaigns/'+ _.$route.params.id + '/submissions/filter?page=1&limit=200' + filter)
        .then(resp => {
          let _ = this
          console.log(resp.data.data)

          let data = resp.data.data.data.map((v,i) => {
            return {
                  id: v.id,
                  no: parseInt(i)+parseInt(1),
                  firstName: v.fullName,
                  age: v.age,
                  gender: v.gender,
                  status_influencer: v.account.verified ? 'Verified' : 'Non Verified',
                  status_approval: v.submissionStatus
              }
          })
          _.hasInfluencer = false
          _.items = data
          _.totalRows = resp.data.data.totalData
          _.perPage = 10
          _.currentPage = resp.data.data.currentPage
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    gotoDetailInfluencer(id) {
      let _ = this
      _.$router.push({name: 'detail-influencer', params: {id: id}, query: { campaignId: _.$route.params.id }})
    },
    setujuiApplicant(id) {
      let _ = this
      _.selectedInfluencer = id
      _.$refs['my-modal-setuju'].show()
    },
    hideModalSetuju() {
      this.$refs['my-modal-setuju'].hide()
    },
    setuju() {
      let _ = this
      console.log('SETUJU')
      axios.put('/advertiser/campaigns/'+_.$route.params.id+'/submissions/accept', {influencerID: _.selectedInfluencer})
        .then(resp => {
          _.getData()
        })
        .catch(err => {
          console.log(err.response)
        })
    },
    tolakApplicant(id) {
      let _ = this
      _.selectedInfluencer = id
      _.$refs['my-modal-tolak'].show()
    },
    hideModalTolak() {
      this.$refs['my-modal-tolak'].hide()
    },
    tolak() {
      let _ = this
      console.log('TOLAK')
      axios.put('/advertiser/campaigns/'+_.$route.params.id+'/submissions/reject', {influencerID: _.selectedInfluencer})
        .then(resp => {
          _.getData()
        })
        .catch(err => {
          console.log(err.response)
        })
    },
    getAgeRange() {
        let _ = this
        axios.get('/misc/types/age-ranges')
            .then(resp => {
                let age_range = resp.data.data
                _.optionsAge = age_range.map( ar => {
                  return {
                    value: ar.id,
                    text: ar.label
                  }
                })
            })
            .catch(err => {
                console.log(err)
            })
    },
    getLocation() {
      let _ = this
      axios.get('/misc/types/provinces')
        .then(resp => {
            let location = resp.data.data
            _.optionsLocation = location.map( lc => {
              return {
                value: 'location=' + lc.name,
                text: lc.name
              }
            })
        })
        .catch(err => {
            console.log(err.response)
        })
    },
    getFilter() {
      let _ = this
      let filter = ''
      
      if ( _.selectedAge ) {
        // ageRange=18-24
        filter += '&' + _.selectedAge
      }

      if ( _.selectedGender ) {
        // gender=Laki-Laki
        filter += '&' + _.selectedGender
      }

      if ( _.selectedStatus ) {
        // verified=1
        filter += '&' + _.selectedStatus
      }

      if ( _.selectedLocation ) {
        // location=ACEH
        filter += '&' + _.selectedLocation
      }

      return filter;
    },
    filterCampaign() {
      let _ = this
      _.getData()
      this.$refs['modal-filter'].hide()
    },
    resetFilter() {
      let _ = this

      _.selectedAge = null
      _.selectedGender = null
      _.selectedStatus = null
      _.selectedLocation = null
      _.getData()
      _.$refs['modal-filter'].hide()
    },
  },
}
</script>
