<template>
    <b-row>
        <b-col cols="12">
            <table-list-applicant></table-list-applicant>
        </b-col>
    </b-row>
</template>

<script>
import TableListApplicant from '@/views/table/bs-table/TableListApplicant.vue'
import {
    BRow, BCol
} from 'bootstrap-vue'

export default {
    name: 'ListApplicant',
    components: {
        TableListApplicant,

        BRow, BCol
    }
}
</script>